import { useEffect } from 'react';

import useApi from '../../../../hooks/useApi';
import useFilterReducer from '../../../../hooks/useFilterReducer';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import Select from '../../../general/input/Select';
import { TextTooltip } from '../../../general/Tooltip';
import Card from './Card';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import {
    faPaperclip,
    faPencilAlt,
    faToolbox,
    faUndo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/card/CurrentJobCard.module.scss';

const buildUrl = ({ url: _url, sort, filters, params = {} }) => {
    let url = _url;

    let optString = Object.keys(params)
        ?.filter?.((opt) => !!params[opt])
        ?.map?.((opt) => `${opt}=${params[opt]}`)
        ?.join?.('&');

    let sortObj = Object.keys(sort)
        ?.sort((a, b) => sort?.[a]?.order - sort?.[b]?.order)
        .reduce(
            (acc, s) => [
                ...acc,
                ...(sort[s].columns
                    ? sort[s].columns
                    : [
                          {
                              Field: s,
                              Direction: sort[s].direction,
                              CaseInsentitive: true
                          }
                      ])
            ],
            []
        );

    let sortString =
        sortObj && sortObj.length > 0 ? `sort=${JSON.stringify(sortObj)}` : '';

    let filterObj = filters
        ? Object.keys(filters)
              ?.filter(
                  (filter) =>
                      !!filters?.[filter]?.getFilter?.(filters?.[filter]?.value)
              )
              .reduce(
                  (acc, cur) => ({
                      ...acc,
                      ...filters[cur].getFilter?.(filters?.[cur]?.value)
                  }),
                  {}
              )
        : {};

    let filterString = `filter=${encodeURIComponent(
        JSON.stringify(filterObj)
    )}`;

    url = `${url}?${[optString, filterString, sortString].join('&')}`;

    return url;
};

const defaultFilterState = {
    tags: {
        value: [],
        getFilter: (tags) =>
            tags.length === 0 ? null : { TagIds: tags.map((tag) => tag?.id) }
    },
    equipment: {
        value: [],
        getFilter: (equipment) =>
            equipment.length === 0
                ? null
                : { EquipmentIds: equipment.map((equip) => equip?.id) }
    },
    formTypes: {
        value: [],
        getFilter: (formTypes) =>
            formTypes.length === 0
                ? null
                : { FormTypeIds: formTypes.map((formType) => formType?.id) }
    }
};

const CurrentJobCard = ({ jobId, handleChangeJob }) => {
    const [{ data }, getJobOverview] = useApi(`/quality/${jobId}`, 'GET', {
        manual: true
    });
    const [{ data: steps }] = useApi('/quality/form/steps', 'GET', {
        manual: false
    });

    const [, setFormFilters] = useLocalStorage('quality_overview_filters');

    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'quality_overview_filters'
    );
    const navigate = useNavigate();

    useEffect(() => {
        getJobOverview({
            url: buildUrl({
                url: `/quality/${jobId}`,
                sort: {},
                filters: filter
            })
        });
    }, [jobId, filter]); //eslint-disable-line

    const clearFilters = () => {
        resetFilter();
    };

    const handleFilterChange = (key, value) => {
        setFilter({
            key: key,
            payload: value
        });
    };

    const handleAwaitingCommissioning = () => {
        let commissioningObj = steps?.find?.(
            (s) => s.name === 'Awaiting Commissioning'
        );
        setFormFilters({
            step: [
                {
                    id: commissioningObj.id,
                    name: commissioningObj.name
                }
            ]
        });
        navigate('../forms');
    };

    const handleAwaitingQC = () => {
        let QCObj = steps?.find?.((s) => s.name === 'Awaiting QC');
        setFormFilters({
            step: [
                {
                    id: QCObj.id,
                    name: QCObj.name
                }
            ]
        });
        navigate('../forms');
    };

    const handleCompleted = () => {
        let CompObj = steps?.find?.((s) => s.name === 'Completed');
        setFormFilters({
            step: [
                {
                    id: CompObj.id,
                    name: CompObj.name
                }
            ]
        });
        navigate('../forms');
    };

    return (
        <Card
            classes={{ container: styles.card }}
            header={<p>Job Overview</p>}
            loading={!data}
            content={
                <div className={styles.container}>
                    <div className={styles.leftContainer}>
                        <div className={styles.jobLabel}>
                            <p>
                                {data?.jobNumber} - {data?.jobName}
                            </p>
                            <span
                                className={styles.edit}
                                onClick={handleChangeJob}
                            >
                                <TextTooltip
                                    tooltip="Change Job"
                                    hoverTrigger="always"
                                    position={{
                                        x: 'right',
                                        y: 'center'
                                    }}
                                    offset={{
                                        x: 16,
                                        y: 0
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </TextTooltip>
                            </span>
                        </div>
                        <div className={styles.metricContainer}>
                            <div className={styles.metricLabel}>
                                <span className={styles.metricIcon}>
                                    <FontAwesomeIcon icon={faToolbox} />
                                </span>
                                <p>Equipment</p>
                            </div>
                            <div className={styles.metricValue}>
                                <p className={styles.metricValueLight}>
                                    {data?.equipmentFinishedCount}
                                </p>
                                /{data?.equipmentCount}
                            </div>
                            <p className={styles.metricQualifier}>Completed</p>
                        </div>
                        <div className={styles.metricContainer}>
                            <div className={styles.metricLabel}>
                                <span className={styles.metricIcon}>
                                    <FontAwesomeIcon icon={faFileLines} />
                                </span>
                                <p>Forms</p>
                            </div>
                            <div className={styles.metricValue}>
                                <p className={styles.metricValueLight}>
                                    {data?.formFinishedCount}
                                </p>
                                /{data?.formCount}
                            </div>
                            <p className={styles.metricQualifier}>Completed</p>
                        </div>
                        <div className={styles.metricContainer}>
                            <div className={styles.metricLabel}>
                                <span className={styles.metricIcon}>
                                    <FontAwesomeIcon icon={faPaperclip} />
                                </span>
                                <p>Attachments</p>
                            </div>
                            <div className={styles.metricValue}>
                                {data?.attachmentCount}
                            </div>
                            <p className={styles.metricQualifier}>Total</p>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.filterContainer}>
                            <Select
                                placeholder="Select Tag(s)"
                                multiselect
                                handleRowSelection={handleFilterChange.bind(
                                    this,
                                    'tags'
                                )}
                                selected={filter['tags'].value}
                                getRowValue={(row) => row?.name}
                                getRowNodeId={(row) => row.id}
                                pagination={{
                                    url: `/quality/${jobId}/tags`,
                                    record: jobId
                                }}
                                modal
                                sort={['name']}
                            />
                            <Select
                                placeholder="Select Equipment"
                                multiselect
                                handleRowSelection={handleFilterChange.bind(
                                    this,
                                    'equipment'
                                )}
                                selected={filter['equipment'].value}
                                getRowValue={(row) => row?.name}
                                getRowNodeId={(row) => row.id}
                                pagination={{
                                    url: `/quality/${jobId}/equipment`,
                                    record: jobId
                                }}
                                modal
                                sort={['equipment']}
                            />
                            <Select
                                placeholder="Select Form Type(s)"
                                multiselect
                                handleRowSelection={handleFilterChange.bind(
                                    this,
                                    'formTypes'
                                )}
                                selected={filter['formTypes'].value}
                                getRowValue={(row) => row?.name}
                                getRowNodeId={(row) => row.id}
                                pagination={{
                                    url: `/quality/${jobId}/form/templates`,
                                    record: jobId
                                }}
                                modal
                                sort={['name']}
                            />
                            <div className={styles.resetFilters}>
                                <div onClick={clearFilters}>
                                    <FontAwesomeIcon icon={faUndo} />
                                    <p>Clear Filters</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.graphContainer}>
                            <div className={styles.pieContainer}>
                                <div
                                    className={styles.pie}
                                    style={{
                                        '--p':
                                            data?.equipmentCount === 0
                                                ? 0
                                                : Math.ceil(
                                                      (data?.equipmentFinishedCount /
                                                          data?.equipmentCount) *
                                                          100
                                                  ),
                                        '--b': '10px',
                                        '--c': 'var(--secondary-theme-color-light)'
                                    }}
                                >
                                    <p>
                                        {data?.equipmentCount === 0
                                            ? 0
                                            : Math.ceil(
                                                  (data?.equipmentFinishedCount /
                                                      data?.equipmentCount) *
                                                      100
                                              )}
                                        %
                                    </p>
                                    <p>Equipment</p>
                                    <p>Completion</p>
                                </div>
                            </div>
                            <div className={styles.pieContainer}>
                                <div
                                    className={styles.pie}
                                    style={{
                                        '--p':
                                            data?.formCount === 0
                                                ? 0
                                                : Math.ceil(
                                                      (data?.formFinishedCount /
                                                          data?.formCount) *
                                                          100
                                                  ),
                                        '--b': '10px',
                                        '--c': 'var(--primary-theme-color-light)'
                                    }}
                                >
                                    <p>
                                        {data?.formCount === 0
                                            ? 0
                                            : Math.ceil(
                                                  (data?.formFinishedCount /
                                                      data?.formCount) *
                                                      100
                                              )}
                                        %
                                    </p>
                                    <div
                                        className={styles.pieAction}
                                        onClick={handleCompleted}
                                    >
                                        <p>({data?.formFinishedCount}) Forms</p>
                                        <p>Completion</p>
                                    </div>
                                </div>
                                {/* (data?.formFinishedCount /
                                                    data?.formCount) *
                                                100
                                            ),
                                    '--b': '10px',
                                    '--c': 'var(--primary-theme-color-light)'
                                }}
                            >
                                <p>
                                    {data?.formCount === 0
                                        ? 0
                                        : Math.ceil(
                                            (data?.formFinishedCount /
                                                data?.formCount) *
                                            100
                                        )}
                                    %
                                </p>
                                <p>Form</p>
                                <p>Completion</p> */}
                            </div>
                            <div className={styles.pieContainer}>
                                <div
                                    className={styles.pie}
                                    style={{
                                        '--p':
                                            data?.formsAwaitingCommissioning ===
                                            0
                                                ? 0
                                                : Math.ceil(
                                                      (data?.formsAwaitingCommissioning /
                                                          data?.formCount) *
                                                          100
                                                  ),
                                        '--b': '10px',
                                        '--c': 'var(--primary-theme-color-light)'
                                    }}
                                >
                                    <p>
                                        {data?.formsAwaitingCommissioning === 0
                                            ? 0
                                            : Math.ceil(
                                                  (data?.formsAwaitingCommissioning /
                                                      data?.formCount) *
                                                      100
                                              )}
                                        %
                                    </p>
                                    <div
                                        className={styles.pieAction}
                                        onClick={handleAwaitingCommissioning}
                                    >
                                        <p>
                                            ({data?.formsAwaitingCommissioning})
                                            Awaiting Commissioning
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.pieContainer}>
                                <div
                                    className={styles.pie}
                                    style={{
                                        '--p':
                                            data?.formsAwaitingQC === 0
                                                ? 0
                                                : Math.ceil(
                                                      (data?.formsAwaitingQC /
                                                          data?.formCount) *
                                                          100
                                                  ),
                                        '--b': '10px',
                                        '--c': 'var(--primary-theme-color-light)'
                                    }}
                                >
                                    <p>
                                        {data?.formsAwaitingQC === 0
                                            ? 0
                                            : Math.ceil(
                                                  (data?.formsAwaitingQC /
                                                      data?.formCount) *
                                                      100
                                              )}
                                        %
                                    </p>
                                    <div
                                        className={styles.pieAction}
                                        onClick={handleAwaitingQC}
                                    >
                                        <p>
                                            ({data?.formsAwaitingQC}) Awaiting
                                        </p>
                                        <p>QC</p>
                                    </div>
                                </div>

                                {/* (data?.equipmentFinishedCount /
                                                    data?.equipmentCount) *
                                                100
                                            ),
                                    '--b': '10px',
                                    '--c': 'var(--secondary-theme-color-light)'
                                }}
                            >
                                <p>
                                    {data?.equipmentCount === 0
                                        ? 0
                                        : Math.ceil(
                                            (data?.equipmentFinishedCount /
                                                data?.equipmentCount) *
                                            100
                                        )}
                                    %
                                </p>
                                <p>Equipment</p>
                                <p>Completion</p> */}
                            </div>
                        </div>
                        {/* <div className={styles.formActionsContainer}>
                            <div
                                className={styles.formAction}
                                onClick={steps && handleAwaitingCommissioning}
                            >
                                <p>{data?.formsAwaitingCommissioning} Forms</p>
                                <p>Awaiting Commissioning</p>
                            </div>
                            <div
                                className={styles.formAction}
                                onClick={steps && handleAwaitingQC}
                            >
                                <p>{data?.formsAwaitingQC} Forms</p>
                                <p>Awaiting QC</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            }
        />
    );
};

export default CurrentJobCard;
